/** 个人中心 */
<template>
  <keep-alive v-if="!$isPc">
    <router-view />
  </keep-alive>
  <el-container v-else class="my content">
    <el-aside :width="$isPc ? '200px' : '100%'" class="personal-navs pb-50">
      <MyMenu />
    </el-aside>
    <el-main class="my-main">
      <keep-alive exclude="MyTradingSellForm,MyTradingBuyForm">
        <router-view />
      </keep-alive>
    </el-main>
  </el-container>
</template>

<script>
import MyMenu from './menu.vue'
export default {
  name: 'My',
  components: {
    MyMenu
  },
}
</script>
<style lang="scss" scoped>
.el-container {
  .my-main {
    padding: 20px 50px;
  }
}

::v-deep .el-table {
  &.el-table--border {
    border: none;

    .el-table__header-wrapper {
      background-color: #8491ae;
    }
  }

  &::before,
  &::after {
    display: none;
  }

  &.el-table--border,
  th.el-table__cell,
  td.el-table__cell {
    border-color: #8491ae;
    border-width: 2px;
  }

  tr {
    td.el-table__cell:last-child {
      border-right-width: 2px;
      border-right-color: #8491ae;
    }

    td.el-table__cell:first-child {
      border-left: 2px solid;
      border-left-color: #8491ae;
    }

    th.el-table__cell.is-leaf {
      border-bottom: none;
    }
  }
}
</style>